import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DateRangePickerExtended from "mlib/common/DateRangePickerExtended";
import DebounceInput from "nlib/ui/DebounceInput";
import Modal from "nlib/ui/Modal";
import Pages from "lib/pages/Pages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import usePageData from "hooks/usePageData";

const {
  ARCHIVE_ATTACHMENT_TYPES: { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT, OTHER }
} = DataConstants;

const Filters = () => {
  const { pathname } = useLocation();

  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const {
    text,
    fromDate,
    toDate,
    type
  } = envVars;

  const [textInputValue, setTextInputValue] = useState(text);

  const [opened, setOpened] = useState(false);

  const [searchFocused, setSearchFocused] = useState(false);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const { titleLangId } = usePageData();

  const { restPath } = useMemo(() => {
    return Pages.getCurrentRouteInfo(pathname);
  }, [pathname]);

  const folderPath = [
    uiTexts[titleLangId],
    restPath.length > 1 && "...",
    restPath[restPath.length - 1]
  ].filter(Boolean);

  const hasFilters = [fromDate, toDate, type].find(Boolean);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: AGREEMENT, label: uiTexts.agreement },
    { value: TAX_STATEMENT, label: uiTexts.taxStatement },
    { value: BANK_STATEMENT, label: uiTexts.bankStatement },
    { value: OTHER, label: uiTexts.other }
  ], [type, uiTexts]);

  const renderTypeLabel = useCallback((label) => {
    return (
      <div className={Css.selectToggleButton}>
        <Icons.File />
        <span className={Css.title}>
          {uiTexts.type}
        </span>
        <span
          className={classNames(Css.label, label && Css.hasSelected)}>
          {label || uiTexts.selectType}
        </span>
      </div>
    );
  }, [uiTexts]);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value });
  }, [setEnvVars]);

  const handleDateChange = useCallback((value) => {
    const [first, second] = value;

    setEnvVars({ fromDate: first, toDate: second });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value });
  }, [setEnvVars]);

  const handleResetClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, type: null });
  }, [setEnvVars]);

  const handleDropDownToggle = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleSearchFocus = useCallback(() => {
    setSearchFocused(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    setSearchFocused(false);
  }, []);

  useEffect(() => {
    setTextInputValue(text);
  }, [text]);

  return (
    <>
      <div className={classNames(Css.filters, hasFilters && Css.active)}>
        <div className={classNames(Css.container, CommonCss.mobileContainer)}>
          <div className={Css.title}>
            <span>{folderPath.join(" / ")}</span>
            <DebounceInput
              cleanable
              className={classNames(Css.search, (!!textInputValue || searchFocused) && Css.focused)}
              active={!!text}
              placeholder={uiTexts.searchDocuments}
              iconBefore={Icons.MagnifyingGlass}
              value={textInputValue}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onChange={setTextInputValue}
              onInputComplete={handleTextInputComplete} />
          </div>
          <Button
            outline
            primary={hasFilters}
            className={Css.moreFilters}
            onClick={handleDropDownToggle}>
            <Icons.Funnel />
          </Button>
        </div>
      </div>
      {opened && (
        <Modal
          title={uiTexts.filters}
          className={Css.filtersModal}
          onClose={handleDropDownToggle}>
          <div className={Css.list}>
            <div className={Css.listItem}>
              <DateRangePickerExtended
                className={Css.datePicker}
                value={fromToDate}
                placeholder={uiTexts.selectDate}
                label={uiTexts.dateRange}
                onChange={handleDateChange} />
            </div>
            <div className={Css.listItem}>
              <Select
                modal
                active={!!type}
                className={Css.select}
                value={type}
                title={uiTexts.selectType}
                options={typeOptions}
                renderLabel={renderTypeLabel}
                onChange={handleTypeChange} />
            </div>
          </div>
          <div className={Css.buttons}>
            <Button
              primary
              outline
              disabled={!hasFilters}
              className={Css.button}
              onClick={handleResetClick}>
              <Icons.X /> {uiTexts.resetAll}
            </Button>
            <Button light className={Css.button} onClick={handleDropDownToggle}>
              {uiTexts.close}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(Filters);
